@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  background-color: #1D1D1D;
}

/* ScrollBar CSS  */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  /* background-color: #1d1d1d; */
}

::-webkit-scrollbar-thumb {
  background-color: #4d4d4d;
  border-radius: 7px;
  /* border: 3.5px solid #1d1d1d; */
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.frq-select {
  width: 100%;
}

.tootip-img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #fff;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

.avatar-group .tootip-img+.tootip-img {
  margin-left: -10px;
}

.col-content {
  flex-grow: 1;
  padding: 24px;
  margin-right: 0;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-list-box th {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 8px 0px;
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 600;
}

.ant-list-box td {
  text-align: left;
  border-bottom: 1px solid #0000000d;
  padding: 16px 0;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 600;
}

.ant-list-box h5 {
  font-size: 14px;
}

.table-responsive {
  overflow-x: auto;
  /* -webkit-overflow-scrolling: touch; */
}

/* .table-responsive::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, green, orange, red) !important;
  border: none;
  border-radius: 7px !important;
}

.table-responsive::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background: transparent !important;
} */

.ant-table-wrapper .ant-table {
  scrollbar-color: unset;
}

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

.ant-table-body::-webkit-scrollbar {
  width: 4px;
}

.ant-table-body::-webkit-scrollbar-track {
  background-color: #e1e6e6;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: green;
}

.ant-muse span {
  color: #8c8c8c;
  font-weight: 600;
}

.ant-muse h5 {
  margin: 0px 0px 15px !important;
  font-size: 20px;
}

.ant-typography.lastweek {
  color: #8c8c8c;
  font-weight: 600;
}

.border10 {
  border-radius: 10px;
}

.text-right {
  text-align: right;
}

.h-full {
  height: 100%;
}

.card-credit .card-footer {
  display: flex;
  align-items: flex-end;
}

.icon-move-right .anticon-right {
  color: #40a9ff;
  margin-left: 2px;
}

.icon-move-right {
  color: #1890ff;

  display: block;
}

.icon-move-right {
  margin-bottom: 15px;
}

.project-ant {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.uploadfile {
  margin-top: 15px;
}

.width-100 {
  width: 100%;
}

.h6,
h6 {
  font-size: 16px;
}

span.blue {
  margin-left: 5px;
  color: #1890ff;
  font-weight: 600;
}

.full-width {
  max-width: 100% !important;
}

.icon-box span {
  color: #fff;
  font-size: 24px;
}

.listpage {
  padding-top: 15px;
}

  /* .bar-chart {
    background: transparent
      linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 4px 6px #0000001f;

    border-radius: 8px;
    width: 100% !important;
    max-width: 100%;
  } */
.pie-chart {
  background: transparent linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 6px #0000001f;

  border-radius: 8px;
  width: 100% !important;
  max-width: 100%;
}

.bnb2 {
  color: #52c41a;
  font-weight: 700;
}

.linechart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.frq-btn.frq-btn-submit {
  @apply bg-green-500;
}


.ql-toolbar.ql-snow {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  /* border-bottom: none !important; */
  border-bottom: 1px solid rgba(128, 128, 128, 0.4) !important;

}

.custom-quill-container .ql-container {
  border: none !important;
}

:where(.css-dev-only-do-not-override-7x9rkw).frq-tabs>.frq-tabs-nav,
:where(.css-dev-only-do-not-override-7x9rkw).frq-tabs>div>.frq-tabs-nav {
  flex-wrap: wrap;
}

:where(.css-dev-only-do-not-override-q1ym3f).frq-tabs>.frq-tabs-nav,
:where(.css-dev-only-do-not-override-q1ym3f).frq-tabs>div>.frq-tabs-nav {
  flex-wrap: wrap;
}


  /* button.frq-btn.frq-btn-submit {
    background: red;
  } */

  /* span.frq-select-tree-node-content-wrapper.frq-select-tree-node-selected {
    background-color: #006927 !important;
  } */

  /* span.frq-tree-node-selected {
    background: #006927 !important;
    color: #ffffff !important;
  } */

.resizable-handle {
  position: absolute;
  right: -5px;
  top: 0;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  background: transparent;
  z-index: 1;
}